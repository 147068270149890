import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Dropdown, Popup, Icon, Divider } from 'semantic-ui-react'
import { observable, action, reaction } from 'mobx'
import { ItemButton, TargetNumberInput, ToolbarButton } from '@code-yellow/spider'
import styled from 'styled-components'
import { t } from 'i18n';
import { notesFormatter } from 'screen/Progress/Views/Default';

// components
import { Body3 } from 'component/PerformanceStyles';
import AdminOverview from 'component/AdminOverview'
import BatchReworkModal from 'component/BatchReworkModal'
import TargetInfoModal from 'component/TargetInfoModal'
import BatchQRModal from 'component/BatchQRModal'
import Barcode from 'react-barcode'
import BatchCreateModal from 'component/Batch/CreateModal'
// end components

// helpers
import { humanReadable } from 'helpers/decimal';
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
// end helpers

// stores
import { BatchStore, RELATED_LINK_RELATIONS } from 'store/Batch'
import { USER_DEFINED_TYPES, TYPES } from 'store/BatchType'
import { ProductionLineStore } from 'store/ProductionLine'
import { MetavalueStore } from 'store/Metavalue'
import { StorageLocationStore } from 'store/StorageLocation'
import { ArticleType } from 'store/ArticleType'
import { BusinessRelationStore } from 'store/BusinessRelation'
// end stores

const MetavaluesContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const MetavalueContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MetavalueLabel = styled.div`
  font-weight: bold;
`

const MetavalueValue = styled.div`
`

@observer
export class InlineMetavalues extends Component {
  /**
   * Show given `metavalues` inline.
   * If `limit` is set, show at most `limit` number of metavalues.
   * If more than `limit` are set, let the user know
   */

  static propTypes = {
    metavalues: PropTypes.instanceOf(MetavalueStore).isRequired,
    articleType: PropTypes.instanceOf(ArticleType).isRequired,
    limit: PropTypes.number,
  }

  render() {
    const { metavalues, limit, articleType } = this.props
    return (
      <>
      <MetavaluesContainer>
        {metavalues.models.slice(0, limit).map((metavalue) => (
          <MetavalueContainer key={metavalue.cid}>
            <MetavalueLabel>{metavalue.metafield.name}</MetavalueLabel>
            <MetavalueValue>{metavalue.value ?? <Popup trigger={<Icon name="image" />} content={<img alt="" src={metavalue.file} />} />}</MetavalueValue>
          </MetavalueContainer>
        ))}
      </MetavaluesContainer>
      {limit && articleType && metavalues.length > limit &&
        <Link to={`/assets/article-type/${articleType.id}/edit`}>
          {t('batch.overview.moreMetavalues', { count: metavalues.length - limit })}
        </Link>}
      </>
    )
  }
}

const StyledTargetNumberInput = styled(TargetNumberInput)`
  > .ui.input > input {
    min-width: 0;
    flex: 1 1 0;
  }
`

const REMAINING_QUANTITY_FILTER_OPTIONS = [
  { value: '.quantity_remaining:gte', text: t('form.greaterThanOrEqual') },
  { value: '.quantity_remaining:lte', text: t('form.lowerThanOrEqual') },
  { value: '.quantity_remaining:gt', text: t('form.greaterThanSign') },
  { value: '.quantity_remaining:lt', text: t('form.lowerThanSign') },
  { value: '.quantity_remaining', text: t('form.equal') },
]

const REMAINING_BEST_BEFORE_FILTER_OPTIONS = [
  { value: '.best_before_remaining:gte', text: t('form.greaterThanOrEqual') },
  { value: '.best_before_remaining:lte', text: t('form.lowerThanOrEqual') },
  { value: '.best_before_remaining:gt', text: t('form.greaterThanSign') },
  { value: '.best_before_remaining:lt', text: t('form.lowerThanSign') },
  { value: '.best_before_remaining', text: t('form.equal') },
]

@observer
export default class BatchOverviewScreen extends AdminOverview {
  store = new BatchStore({
    relations: [
      'performances',
      'flatMetavalues.metafield',
      'warehouse',
      'storageLocation.warehouse.storageLocations',
      'batchType.articleType.exactItem',
      'loadCarrier',
      ...RELATED_LINK_RELATIONS,
    ],
    params: {
      include_annotations: '*,resettable,scrappable,last_performance,rework_deletes_carrier_rows',
    },
  })
  productionLineStore = new ProductionLineStore()
  storageLocationStore = new StorageLocationStore({
    params: {
      order_by: 'code',
    },
    relations: ['warehouse'],
  })
  businessRelationStore = new BusinessRelationStore()

  title = t('batch.overview.title')
  emptyMessage = t('batch.overview.empty')
  myFilterKey = 'batch-overview'
  fullWidth = true

  params = {
    '.finished': 'true',
    '.scrapped': 'false',
    '.quantity_remaining:gt': '0',
    '.batch_type.type:in': USER_DEFINED_TYPES.join(','),
  }

  @observable remainingQuantityFilter = '.quantity_remaining:gt'
  @observable remainingBestBeforeFilter = '.best_before_remaining:lte'

  settings = [
    {
      label: null,
      collapsing: true,
      cellProps: { style: { whiteSpace: 'nowrap' } },
      attr: (batch) => {
        const articleType = batch.batchType.articleType

        return (
          <>
            {batch.productionRequest.getLink({
              hover: (
                <>
                  {t('productionRequest.overview.productionRequestID')}: {batch.productionRequest && batch.productionRequest.id}{' '}
                  <br />
                  {t('productionRequest.field.project.label')}:{' '}
                  {batch.productionRequest.project && batch.productionRequest.project.code}
                </>
              ),
            })}
            {!articleType.isNew && articleType.getLink()}
            {batch.getRelatedLinks()}
            {!batch.loadCarrier.isNew && batch.loadCarrier.getLink()}
            <Body3 data-test-batch-article-type-name>
              <br />
              {notesFormatter(articleType.name, 40)}
            </Body3>
          </>
        )
      },
    },
    {
      label: t('batch.field.serialNumber.label'),
      attr: batch => (
        <Popup trigger={<span data-test-serial-number>{batch.serialNumber}</span>}>
          <Barcode
            value={batch.serialNumber}
            width={3}
            height={60}
            fontSize={10}
          />
        </Popup>
      )
    },
    {
      label: t('batch.field.metavalues.label'),
      attr: (batch) => <InlineMetavalues metavalues={batch.flatMetavalues} limit={3} articleType={batch.batchType.articleType} />,
    },
    {
      label: t('batch.field.quantityRemaining.shortLabel'),
      attr: batch => `${humanReadable(batch.quantityRemaining)}/${humanReadable(batch.quantity)}`,
      collapsing: true,
      cellProps: { 'data-test-quantity-remaining': true }
    },
    {
      label: t('batch.field.storageLocation.shortLabel'),
      attr: batch => (
        <span>
          {!batch.warehouse?.isNew && batch.warehouse?.getLink()}
          {!batch.storageLocation.isNew && batch.storageLocation.getLink()}
        </span>
      ),
      sortKey: 'storage_location.code',
    },
    {
      label: t('batch.field.details.bestBeforeDate.shortLabel'),
      attr: 'bestBeforeDate',
    },
    'createdAt',
    'finished',
    {
      label: t('batch.field.scrapped.label'),
      attr: 'scrapReason',
    },
    ...isFeatureFlagEnabled('batch_qr_code') ? [{
      label: 'QR Code',
      attr: (batch) => <BatchQRModal batch={batch} />,
    }] : [],
    { collapsing: true },
  ]

  buttons = [
    {
      type: 'custom',
      callback: (batch) => (
        <BatchReworkModal
          target={batch}
          trigger={props => (
            <ItemButton
              {...props}
              data-test-rework-batch={batch.serialNumber}
              primary={false}
              icon="redo"
              label={t('batch.button.rework.label')}
            />
          )}
        />
      ),
    },
    {
      type: 'custom',
      callback: (batch) => (
        <TargetInfoModal
          target={batch}
          trigger={<ItemButton data-test-view-batch={batch.serialNumber} label={t('batch.button.batchesView.label')} primary={false} icon="eye" />}
        />
      ),
    },
    {
      type: 'delete',
      canDelete: batch => batch.finished,
      negative: true,
    }
  ]

  componentDidMount() {
    this.store.params['.quantity_remaining:gt'] = 0
    super.componentDidMount()
    this.paramsReaction = reaction(
      () => this.store.params,
      action((params) => {
        const remainingQuantityOption = REMAINING_QUANTITY_FILTER_OPTIONS.find(({ value }) => params[value])
        if (remainingQuantityOption && remainingQuantityOption.value !== this.remainingQuantityFilter) {
          this.remainingQuantityFilter = remainingQuantityOption.value
        }
        const remainingBestBeforeOption = REMAINING_BEST_BEFORE_FILTER_OPTIONS.find(({ value }) => params[value])
        if (remainingBestBeforeOption && remainingBestBeforeOption.value !== this.remainingBestBeforeFilter) {
          this.remainingBestBeforeFilter = remainingQuantityOption.value
        }
      }),
      { fireImmediately: true }
    )
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.paramsReaction()
  }

  filters = [
    {
      type: 'text',
      label: t('batch.filter.articleTypeCode'),
      name: '.batch_type.article_type.code:icontains',
    },
    {
      type: 'text',
      label: t('batch.filter.articleTypeName'),
      name: '.batch_type.article_type.name:icontains',
    },
    {
      type: 'text',
      label: t('batch.field.serialNumber.label'),
      name: '.serial_number:icontains',
    },
    {
      type: 'text',
      label: t('batch.field.batchUseds.label'),
      name: '.batch_usings.used_batch.serial_number:icontains',
    },
    {
      type: 'text',
      label: t('batch.filter.details'),
      name: '.detail_values',
    },
    {
      type: 'text',
      label: t('batch.filter.bomItems'),
      name: '.bom_items',
    },
    () => ({
      type: 'select',
      label: t('batch.field.storageLocation.label'),
      name: '.storage_location:in',
      remote: true,
      multiple: true,
      store: this.storageLocationStore,
      toOption: sl => ({
        text: `${sl.code} ${sl.name} (${sl.warehouse.name})`,
        value: sl.id,
      }),
    }),
    {
      type: 'custom',
      callback: () => (
        <StyledTargetNumberInput data-test-batch-quantity-remaining-input
          label={t('batch.field.quantityRemaining.label')}
          target={this.store}
          name={this.remainingQuantityFilter}
          afterChange={this.debouncedFetch}
          contentProps={{
            label: (
              <Dropdown data-test-stock-equals
                value={this.remainingQuantityFilter}
                onChange={action((e, { value }) => {
                  // default params has quantity_remaing:gt: 0
                  // remove this from this.params and then fetch
                  if (this.params['.quantity_remaining:gt'] !== undefined) {
                    delete this.params['.quantity_remaining:gt']
                    this.store.params[value] = this.store.params[this.remainingQuantityFilter]
                    delete this.store.params['.quantity_remaining:gt']
                    this.fetch()
                  }

                  // incase number is filled in, we also fetch
                  if (this.store.params[this.remainingQuantityFilter]) {
                    this.store.params[value] = this.store.params[this.remainingQuantityFilter]
                    delete this.store.params[this.remainingQuantityFilter]
                    this.fetch()
                  }

                  // always set >, <, >=, <=, =
                  this.remainingQuantityFilter = value
                })}
                options={REMAINING_QUANTITY_FILTER_OPTIONS}
              />
            ),
            labelPosition: 'left',
          }}
        />
      ),
    },
    () => ({
      type: 'multiPick',
      label: t('batchType.field.type.label'),
      name: '.batch_type.type:in',
      options: TYPES.map((type) => ({
        text: t(`batchType.field.type.value.${type}`),
        value: type,
      })),
    }),
    {
      type: 'bool',
      label: t('batch.field.finished.label'),
      name: '.finished',
    },
    {
      type: 'bool',
      label: t('batch.field.scrapped.label'),
      name: '.scrapped',
    },
    {
      type: 'radioButtons',
      label: t('salesOrder.filters.deleted'),
      name: 'deleted',
      options: [
        { text: t('form.yes'), value: 'only' },
        { text: t('form.either'), value: true },
        { text: t('form.no'), value: undefined },
      ]
    },
    {
      label: t('batch.filter.dateFinished'),
      type: 'dateRange',
      name: '.finalized_at:range',
    },
    {
      label: t('batch.filter.dateCreated'),
      type: 'dateRange',
      name: '.created_at:range',
    },
    {
      label: t('batch.filter.dateReceived'),
      type: 'dateRange',
      name: '.storage_location_at:range',
    },
    () => ({
      type: 'select',
      multiple: true,
      label: `${t('purchaseOrder.field.supplier.label')} / ${t('salesOrder.field.customer.label')}`,
      name: '.production_request.business_relation:in',
      store: this.businessRelationStore,
      toOption: (relation) => ({
        value: relation.id,
        text: relation.name,
      }),
      remote: true,
      searchKey: '.name:icontains',
      targetProps: { type: 'int' },
    }),
    { type: 'metafields', withDivider: true },
    {
      type: 'custom',
      callback: () => <Divider horizontal>{t('formField.name.label')}</Divider>,
    },
    {
      label: t('formField.field.bestBefore.date'),
      type: 'dateRange',
      name: '.best_before_date:range',
    },
    {
      type: 'custom',
      callback: () => (
        <StyledTargetNumberInput data-test-best-before-remaining-input
          label={t('formField.field.bestBefore.remaining')}
          target={this.store}
          name={this.remainingBestBeforeFilter}
          afterChange={this.debouncedFetch}
          contentProps={{
            label: (
              <Dropdown data-test-best-before-remaining-options
                value={this.remainingBestBeforeFilter}
                onChange={action((e, { value }) => {
                  if (this.store.params[this.remainingBestBeforeFilter]) {
                    this.store.params[value] = this.store.params[this.remainingBestBeforeFilter]
                    delete this.store.params[this.remainingBestBeforeFilter]
                    this.fetch()
                  }
                  this.remainingBestBeforeFilter = value
                })}
                options={REMAINING_BEST_BEFORE_FILTER_OPTIONS}
              />
            ),
            labelPosition: 'left',
          }}
        />
      ),
    },
    {
      type: 'custom',
      callback: () => <Divider horizontal>IDs</Divider>,
    },
    {
      type: 'number',
      label: t('productionRequest.filter.erpId'),
      name: '.production_request.erp_number',
    },
    {
      type: 'number',
      label: t('batch.field.productionRequest.label'),
      name: '.production_request.id',
    },
    {
      type: 'number',
      label: t('batch.field.productionOrder.label'),
      name: '.production_request.production_order.id',
    },
    {
      type: 'number',
      label: t('batch.field.inShipment.label'),
      name: '.production_request.flat_in_shipment.id',
    },
    {
      type: 'number',
      label: t('batch.field.outShipment.label'),
      name: '.production_request.flat_out_shipment.id',
    },
    {
      type: 'number',
      label: t('batch.field.warehouseTransfer.label'),
      name: '.production_request.warehouse_transfer_line.warehouse_transfer.id',
    },
  ]

  async exportCsv() {
    this.exportIsLoading = true
    try {
      const res = await this.store.api.get('/batch/batches_export/', this.store.params)
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('export', `${t('batch.button.export.fileName')}.csv`);
      document.body.appendChild(link);
      link.click();
      this.setState({ downloading: false });
    } finally {
      this.exportIsLoading = false;
    }
  }

  toolbar = [
    () => <BatchCreateModal />,
    () => (
      <ToolbarButton
        data-test-batch-export-button
        icon="download"
        loading={this.exportIsLoading}
        testUrl={'/api/batch/batches_export/'}
        content={t('batch.button.export.label')}
        onClick={() => this.exportCsv()}
      />
    ),
  ]

  rowProps(batch) {
    return {
      'data-test-batch': batch.id,
    }
  }
}
